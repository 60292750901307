/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.description {
    font-size: 1rem;
    margin-top: 0px;
    margin-bottom: 10px;
}