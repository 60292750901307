/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.container {
    width: 100%;
    height: 100vh;
    background-color: #134835;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    overflow: hidden;
}

.text {
    color: white;
    font-size: 0.95rem;
}

.absolute_logo {
    position: absolute;
    bottom: -150px;
    right: -150px;
    width: 35%;
    opacity: 0.5;
}
