/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}

.image_buttons_container {
    width: 25%;
}

.project_image {
    width: 90%;
    -webkit-box-shadow: 0 0 15px 5px rgb(0 0 0 / 8%);
            box-shadow: 0 0 15px 5px rgb(0 0 0 / 8%);
    border-radius: 10px;
}

.project_image_new {
    width: 100%;
    -webkit-box-shadow: 0 0 15px 5px rgb(0 0 0 / 8%);
            box-shadow: 0 0 15px 5px rgb(0 0 0 / 8%);
    border-radius: 10px;
    margin: 0 auto;
}

.description_container {
    width: 72%;
}

.button {
    padding: 20px 15px;
    background-color: #830019;
    width: 90%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 15px 0px;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 15px 5px rgb(0 0 0 / 4%);
            box-shadow: 0 0 15px 5px rgb(0 0 0 / 4%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    cursor: pointer;
    -webkit-transition: 0.5s ease;
    -o-transition: 0.5s ease;
    transition: 0.5s ease;
    text-decoration: none;
}

.button:hover {
    background-color: #134835;
}

.button_text {
    color: white;
    margin: 0px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.9rem;
    text-decoration: none;
}

.yt_centered {
    width: 75%;
    margin: 30px auto;
    -webkit-box-shadow: 0 0 15px 5px rgb(0 0 0 / 8%);
            box-shadow: 0 0 15px 5px rgb(0 0 0 / 8%);
}

.yt_contain {
    display: block;
}

.description {
    font-size: 0.98rem;
    line-height: 1.25rem;
}
.imageDetails{
    font-size: 0.8rem;
    line-height: 1.25rem;
    text-align: center;
}

@media only screen and (max-width: 1100px) {
    .image_buttons_container {
        width: 28%;
    }

    .description_container {
        width: 70%;
    }
}

@media only screen and (max-width: 950px) {
    .yt_centered {
        width: 100%;
    }
    .button_text {
        font-size: 0.8rem;
    }
}

@media only screen and (max-width: 800px) {
    .image_buttons_container {
        width: 35%;
    }

    .description_container {
        width: 62%;
    }
}

@media only screen and (max-width: 650px) {
    .image_buttons_container {
        width: 45%;
    }
    
    .description_container {
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .image_buttons_container {
        width: 50%;
    }
}

@media only screen and (max-width: 550px) {
    .image_buttons_container {
        width: 55%;
    }
}

@media only screen and (max-width: 500px) {
    .image_buttons_container {
        width: 60%;
    }
}

@media only screen and (max-width: 440px) {
    .image_buttons_container {
        width: 70%;
    }
}