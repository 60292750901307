.video {
    pointer-events: none;
}

.container {
    display: flex;
    height: calc(100vh - 100px);
    overflow: hidden;
    margin-top: 100px;
    position: relative;
}

.text_container {
    background-color: #134835;
    position: relative;
    width: 40%;
}

.upper_fadedLogo, .lower_fadedLogo {
    opacity: 0.12;
}

.upper_fadedLogo {
    position: absolute;
    top: -125px;
    left: -120px;
    width: 250px;
}

.lower_fadedLogo {
    position: absolute;
    bottom: -200px;
    left: -120px;
    width: 400px;
}

.content_container {
    margin: 0px 100px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.video_container {
    width: 60%;
    position: relative;
    overflow: hidden;
}

.video, .video_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.video_image {
    position: absolute;
    top: 0px;
    left: 0px;
    filter: blur(4px);
}

.dot_separator {
    display: flex;
    align-items: center;
}

.dot {
    width: 9px;
    height: 9px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: #FF234E;
}

.heading {
    font-size: 2rem;
    margin: 12px 0px;
    color: white;
    text-transform: uppercase;
}

.sub_title {
    font-size: 1rem;
    margin: 12px 0px;
    color: white;
}

@media only screen and (max-width: 1250px) {
    .content_container {
        margin: 0px 80px;
    }
}

@media only screen and (max-width: 1100px) {
    .content_container {
        margin: 0px 60px;
    }
    .text_container {
        width: 45%;
    }
    .video_container {
        width: 55%;
    }
}

@media only screen and (max-width: 950px) {
    .content_container {
        margin: 0px 50px;
    }
    .text_container {
        width: 50%;
    }
    .video_container {
        width: 50%;
    }
}

@media only screen and (max-width: 800px) {
    .text_container {
        width: 100%;
        position: relative;
        z-index: 3;
        background-color: rgba(19, 72, 53, 0.97);
    }
    .video_container {
        position: absolute;
        width: 100%;
        top: 0px;
        left: 0px;
        height: 100%;
        z-index: 2;
        display: none;
    }
    .lower_fadedLogo {
        right: -120px;
        left: unset;
    }
}

@media only screen and (max-width: 600px) {
    .content_container {
        margin: 0px 30px;
    }
}