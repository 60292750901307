/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.container {
    height: 250px;
    padding: 0px 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative;
    margin-bottom: 75px;
    margin-top: 100px;
}

.background_container {
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(19, 72, 53, 1)), color-stop(rgba(19, 72, 53, 1)), color-stop(rgba(19, 72, 53, 1)), color-stop(rgba(19, 72, 53, 1)), color-stop(rgba(19, 72, 53, 1)), color-stop(rgba(19, 72, 54, 1)), color-stop(rgba(19, 72, 54, 1)), color-stop(rgba(19, 72, 55, 1)), color-stop(rgba(19, 72, 56, 0.9)), color-stop(rgba(20, 78, 58, 0.7)), color-stop(rgba(20, 80, 59, 0.4)), to(rgba(21, 82, 60, 0.3)));
    background-image: -o-linear-gradient(left, rgba(19, 72, 53, 1), rgba(19, 72, 53, 1), rgba(19, 72, 53, 1), rgba(19, 72, 53, 1), rgba(19, 72, 53, 1), rgba(19, 72, 54, 1), rgba(19, 72, 54, 1), rgba(19, 72, 55, 1), rgba(19, 72, 56, 0.9), rgba(20, 78, 58, 0.7), rgba(20, 80, 59, 0.4), rgba(21, 82, 60, 0.3));
    background-image: linear-gradient(to right, rgba(19, 72, 53, 1), rgba(19, 72, 53, 1), rgba(19, 72, 53, 1), rgba(19, 72, 53, 1), rgba(19, 72, 53, 1), rgba(19, 72, 54, 1), rgba(19, 72, 54, 1), rgba(19, 72, 55, 1), rgba(19, 72, 56, 0.9), rgba(20, 78, 58, 0.7), rgba(20, 80, 59, 0.4), rgba(21, 82, 60, 0.3));
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    width: 100%;
    height: 100%;
}

.title_media {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100%;
    width: 40%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
    z-index: 1;
}

.title_container {
    position: relative;
    z-index: 3;
    color: white;
}

.title {
    font-size: 2rem;
    max-width: 80%;
}

.subtitle {
    font-size: 0.95rem;
    margin-top: 30px;
    margin-bottom: 5px;
}

.dop {
    margin: 0px;
    font-size: 0.9rem;
}

@media only screen and (max-width: 1100px) {
    .container {
        padding: 0px 60px;
    }
}

@media only screen and (max-width: 950px) {
    .container {
        padding: 0px 50px;
    }
    .title {
        font-size: 1.8rem;
        max-width: 100%;
    }
}

@media only screen and (max-width: 800px) {
    .title {
        font-size: 1.6rem;
    }
    
    .subtitle {
        font-size: 0.93rem;
        margin-top: 15px;
    }
    
    .dop {
        font-size: 0.88rem;
    }

}

@media only screen and (max-width: 600px) {
    .container {
        padding: 0px 30px;
    }
    .title {
        font-size: 1.5rem;
    }
    
    .subtitle {
        font-size: 0.90rem;
        margin-top: 15px;
    }
    
    .dop {
        font-size: 0.85rem;
    }
}