.navbar_main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 80px;
    height: 100px;
    position: relative;
    z-index: 5;
    width: 100vw;
    box-sizing: border-box;
    background-color: white;
    position: fixed;
    top: 0px;
    transition: 0.5s ease;
}

.sticky_nav {
    box-shadow: 0 0 15px 5px rgb(0 0 0 / 8%);
    background-color: #dfd8d8;
}

.nav_logo {
    width: 125px;
    cursor: pointer;
}

.desk_nav_container {
    display: flex;
}

.nav_text {
    font-size: 0.8rem;
    margin: 0px 15px;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
    transition: .15s ease-in-out;
    padding: 8px;
    border-radius: 4px;
}

.nav_text:hover {
    background-color: #134835;
    color: #fff;
    -webkit-box-shadow: -6px 1px 35px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: -6px 1px 35px 0px rgba(0,0,0,0.2);
    box-shadow: -6px 1px 35px 0px rgba(0,0,0,0.2);
}

.social_container {
    display: flex;
    align-items: center;
}

.social_icon {
    margin-left: 6px;
    cursor: pointer;
    transition: 0.2s ease;
}

.social_icon:hover {
    transform: scale(1.1);
}

.mobile_nav_container {
    display: none;
}

.mobile_nav_icon {
    display: none;
}

.close_icon {
    position: absolute;
    top: 30px;
    right: 50px;
    color: white;
    font-size: 35px;
    transition: 0.4s ease;
    cursor: pointer;
}

.close_icon:hover {
    transform: scale(1.1);
}

@media only screen and (max-width: 1100px) {
    .navbar_main {
        padding: 15px 60px;
    }
    .nav_text {
        margin: 0px 5px;
    }
}

@media only screen and (max-width: 950px) {
    .navbar_main {
        padding: 15px 50px;
    }
    .nav_text {
        font-size: 0.7rem;
        margin: 0px 4px;
    }
    .social_icon {
        margin-left: 5px;
        font-size: 0.95rem;
    }
}

@media only screen and (max-width: 850px) {
    .nav_text {
        font-size: 0.7rem;
        margin: 0px 2px;
    }
    .social_icon {
        margin-left: 5px;
        font-size: 0.85rem;
    }
    .social_container {
        display: none;
    }
}

@media only screen and (max-width: 750px) {
    .nav_text {
        font-size: 0.7rem;
        margin: 0px 2px;
        display: none;
    }
    .mobile_nav_icon {
        font-size: 35px;
        display: block;
        cursor: pointer;
    }
    .mobile_nav_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        background-color: #333;
    }
    .mob_nav_text {
        color: white;
        text-align: center;
        cursor: pointer;
    }

    .social_container_mob {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
    }

    .social_icon_mob {
        color: white;
        margin: 0px 5px;
        cursor: pointer;
        transition: 0.4s ease;
    }

    .social_icon_mob:hover {
        transform: scale(1.1);
    }
}

@media only screen and (max-width: 600px) {
    .close_icon {
        right: 30px;
    }
    .navbar_main {
        padding: 15px 30px;
    }
}

