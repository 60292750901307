/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.heading {
    font-size: 1.8rem;
}

.flexed_projects {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 48%;
    margin-right: 2%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    text-decoration: none;
    color: inherit;
    margin-bottom: 50px;
}

.title_image {
    width: 33%;
    -webkit-box-shadow: 0 0 15px 5px rgb(0 0 0 / 8%);
            box-shadow: 0 0 15px 5px rgb(0 0 0 / 8%);
    border-radius: 10px;
    height: 275px;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
}

.content_container {
    width: 65%;
}

.title {
    font-size: 1.1rem;
    margin: 0px;
}

.dob {
    font-size: 0.95rem;
    margin-top: 10px;
    margin-bottom: 0px;
    font-weight: 600;
}

.description {
    font-size: 0.9rem;
}

.sectors {
    font-size: 0.95rem;
    margin-top: 5px;
    margin-bottom: 0px;
}

@media only screen and (max-width: 1150px) {
    .title_image {
        width: 41%;
        height: 275px;
    }
    .content_container {
        width: 57%;
    }
    .title {
        font-size: 1rem;
    }
    
    .dob {
        font-size: 0.90rem;
        margin-top: 5px;
    }
    
    .description {
        font-size: 0.8rem;
    }

    .description > p {
        margin-top: 5px;
    }
    
    .sectors {
        font-size: 0.90rem;
    }
}

@media only screen and (max-width: 950px) {
    .title_image {
        width: 47%;
        height: 275px;
    }
    .content_container {
        width: 51%;
    }
    
}

@media only screen and (max-width: 900px) {
    .title {
        font-size: 1rem;
    }
    
    .dob {
        font-size: 0.8rem;
    }
    
    .description {
        font-size: 0.75rem;
    }
    
    .sectors {
        font-size: 0.8rem;
    }
}

@media only screen and (max-width: 800px) {
    .container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start;
        width: 32%;
        margin-right: 1%;
    }
    .content_container {
        width: 100%;
    }
    .title_image {
        height: 280px;
        width: 90%;
    }
    .content_container {
        margin-top: 15px;
    }
    .dob {
        font-size: 0.9rem;
    }
    
    .description {
        font-size: 0.85rem;
    }
    
    .sectors {
        font-size: 0.9rem;
    }
}

@media only screen and (max-width: 700px) {
    .content_container {
        width: 100%;
    }
    .title_image {
        height: 265px;
        width: 100%;
    }
}

@media only screen and (max-width: 650px) {
    .container {
        width: 48%;
        margin-right: 2%;
    }
    .title_image {
        height: 270px;
        width: 85%;
    }
}

@media only screen and (max-width: 570px) {
    .title_image {
        height: 270px;
        width: 100%;
    }
}

@media only screen and (max-width: 440px) {
    .title_image {
        height: 240px;
        width: 100%;
    }
}

@media only screen and (max-width: 350px) {
    .container {
        width: 100%;
        margin-right: 0px;
    }
    .title_image {
        width: 65%;
    }
}