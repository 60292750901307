.container {
    background-color: #e5e5e5;
    padding: 30px 80px;
    z-index: 2;
    position: relative;
    margin-top: 90px;
}

.flexed_content, .info_container, .social_container {
    display: flex;
}

.info_container {
    flex-grow: 1;
    justify-content: space-between;
}

.heading {
    margin: 0px;
}

.nav_logo {
    width: 200px;
    margin-right: 75px;
    object-fit: contain;
}

.social_icon {
    margin-left: 13px;
    transition: 0.2s ease;
    cursor: pointer;
}

.social_icon:hover {
    transform: scale(1.1);
}

.copyright_disclaimer {
    text-align: right;
    font-weight: 600;
    font-size: 0.85rem;
    margin: 0px;
}

.address {
    font-size: 0.975rem;
    line-height: 1.2rem;
}

@media only screen and (max-width: 1100px) {
    .heading {
        font-size: 1.5rem;
    }
    
    .nav_logo {
        width: 160px;
        margin-right: 50px;
    }
    
    .social_icon {
        margin-left: 8px;
    }
    
    .social_icon:hover {
        transform: scale(1.1);
    }
    
    .copyright_disclaimer {
        text-align: right;
        font-weight: 600;
        font-size: 0.75rem;
        margin: 0px;
    }
    
    .address {
        font-size: 0.9rem;
        line-height: 1.2rem;
    }
    .container {
        padding: 30px 60px;
    }  
}

@media only screen and (max-width: 950px) {
    .heading {
        font-size: 1.5rem;
    }
    
    .nav_logo {
        width: 120px;
        margin-right: 40px;
    }
    
    .social_icon {
        margin-left: 8px;
    }
    
    .social_icon:hover {
        transform: scale(1.1);
    }
    
    .copyright_disclaimer {
        text-align: right;
        font-weight: 600;
        font-size: 0.75rem;
        margin: 0px;
    }
    
    .address {
        font-size: 0.9rem;
        line-height: 1.2rem;
    }
    .container {
        padding: 30px 50px;
    }  
}

@media only screen and (max-width: 600px) {
    .nav_logo {
        width: 200px;
        margin-right: 40px;
        margin-bottom: 20px;
    }
    .container {
        padding: 30px 30px;
    }  
    .flexed_content, .info_container {
        flex-direction: column;
    }
    .social_icon {
        margin-left: 0px;
        margin-right: 8px;
    }
    .copyright_disclaimer {
        text-align: left;
        margin-top: 30px;
    }
}