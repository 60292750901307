/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.title {
    font-size: 2rem;
    margin: 0px;
    text-align: center;
}

.sub_title {
    font-size: 1rem;
    margin: 0px;
    margin-top: 15px;
    margin-bottom: 20px;
    text-align: center;
}

.form_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin: 0px 50px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    z-index: 2;
}

.input_row {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-bottom: 10px;
}

.half_input {
    width: 48%;
}

.relative_container {
    position: relative;
}

.absolute_logo {
    position: absolute;
    right: -150px;
    bottom: -200px;
    z-index: 1;
    opacity: 0.1;
    width: 500px;
}

.checkbox_cont {
    margin-top: 20px;
}

.absolute_logo_small {
    position: absolute;
    left: -150px;
    top: -100px;
    width: 200px;
    z-index: 1;
    opacity: 0.1;
}

.message {
    height: 1.5rem;
}

.status {
    font-size: 0.8rem;
}


@media only screen and (max-width: 750px) {
    .form_container {
        margin: 0px 20px;
    }
}

@media only screen and (max-width: 650px) {
    .form_container {
        margin: 0px 10px;
    }
}

@media only screen and (max-width: 600px) {
    .input_row {
        margin-bottom: unset;
    }
    .half_input {
        width: 100%;
    }
    .absolute_logo {
        position: absolute;
        right: -150px;
        bottom: -200px;
        z-index: 1;
        opacity: 0.1;
        width: 350px;
    }
    
    .absolute_logo_small {
        position: absolute;
        left: -150px;
        top: -100px;
        width: 200px;
        z-index: 1;
        opacity: 0.1;
    }
}