/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.title {
    font-size: 2rem;
}

.container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}

.trustee {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 32%;
    margin-right: 1%;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    text-decoration: none;
    color: black;
    margin-bottom: 30px;
}

.image {
    width: 48%;
    padding-top: 48%;
    background-position: top;
    background-size: cover;
}

.details_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 52%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding-left: 12px;
}

.name {
    font-size: 1.15rem;
    margin: 0px;
}

.separator {
    width: 25%;
    height: 4px;
    background-color: #830019;
    margin: 8px 0px;
}

.desigation {
    font-size: 0.9rem;
    margin: 0px;
}

.org {
    font-size: 0.85rem;
    margin: 8px 0px;
}

.country {
    font-size: 0.85rem;
    margin: 0px;
}

@media only screen and (max-width: 950px) {
    .trustee {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 48%;
        margin-right: 2%;
    }
}

@media only screen and (max-width: 750px) {
    .image {
        width: 52%;
        padding-top: 52%;
        background-position: top;
        background-size: cover;
    }
    
    .details_container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 45%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        padding-left: 12px;
    }
}

@media only screen and (max-width: 625px) {
    .image {
        width: 40%;
        padding-top: 40%;
        background-position: top;
        background-size: cover;
    }
    .trustee {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        margin-right: 0px;
    }
}

@media only screen and (max-width: 500px) {
    .name {
        font-size: 1rem;
        margin: 0px;
    }
    .org {
        font-size: 0.8rem;
        margin: 8px 0px;
    }
    
    .country {
        font-size: 0.8rem;
        margin: 0px;
    }
}

@media only screen and (max-width: 440px) {
    .name {
        font-size: 0.9rem;
        margin: 0px;
    }
    .org {
        font-size: 0.7rem;
        margin: 8px 0px;
    }
    
    .country {
        font-size: 0.7rem;
        margin: 0px;
    }
}