/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.modal_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 10;
}

.background_container {
    position: absolute;
    background-color: rgba(255,255,255,0.4);
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.content_container {
    width: 70%;
    padding: 45px;
    background-color: #e0e0e0;
    z-index: 3;
    max-height: 74vh;
    min-height: 74vh;
    overflow-y: scroll;
    position: relative;
}

.image_name {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.image {
    width: 200px;
    padding-top: 200px;
    background-size: cover;
    background-position: top;
}

.details_container {
    width: calc(100% - 220px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 20px 0px;
}

.name {
    font-size: 1.4rem;
    margin-bottom: 10px;
    margin-top: 0px;
}

.desigation, .org, .country {
    margin-top: 5px;
    margin-bottom: 0px;
}

.desc {
    margin-top: 20px;
}

.desc > p {
    font-size: 0.85rem;
    margin: 10px 0px;
}

.event_desc > p {
    font-size: 0.92rem;
    margin: 10px 0px;
}

.org , .country {
    font-size: 0.9rem;
}

.loading {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    height: 100%;
}

.flexed_event_modal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 100%;
}

.image_register {
    width: 29%;
}

.event_description {
    width: 69%;
}

.event_image {
    width: 90%;
    -webkit-box-shadow: 0 0 15px 5px rgb(0 0 0 / 8%);
            box-shadow: 0 0 15px 5px rgb(0 0 0 / 8%);
    border-radius: 10px;
}

.venue_container {
    margin-top: 30px;
}

.event_type {
    font-size: 0.85em;
    font-weight: 600;
}

.close_icon {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 30px;
    cursor: pointer;
    color: black;
    -webkit-transition: 0.35s ease;
    -o-transition: 0.35s ease;
    transition: 0.35s ease;
}

.close_icon:hover {
    -webkit-transform: scale(1.08);
        -ms-transform: scale(1.08);
            transform: scale(1.08);
}

@media only screen and (max-width: 1150px) {
    .desc > p {
        font-size: 0.80rem;
        margin: 10px 0px;
    }
    
    .event_desc > p, .event_desc {
        font-size: 0.85rem;
        margin: 10px 0px;
    }
}

@media only screen and (max-width: 975px) {
    .content_container {
        width: 100%;
        min-height: 100vh;
        max-height: 100vh;
        padding: 30px 50px;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        display: block;
    }
}

@media only screen and (max-width: 700px) {
    .flexed_event_modal {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        height: 100%;
        padding-top: 100px;
    }
    .image_register {
        width: 35%;
    }
    
    .event_description {
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .content_container {
        padding: 25px 30px;
    }
    .image_register {
        width: 42%;
    }
}

@media only screen and (max-width: 500px) {
    .content_container {
        padding: 25px 30px;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        padding-top: 75px;
    }
    .flexed_event_modal {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        height: 100%;
        padding-top: 50px;
    }
    .image_register {
        width: 60%;
    }
}

@media only screen and (max-width: 400px) {
    .image_name {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
    .details_container {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        padding: 20px 0px;
    }
}
 