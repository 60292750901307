.section {
    margin: 60px 80px;
}

.button {
    display: flex;
    align-items: center;
    margin: 15px 0px;
    cursor: pointer;
    border: none;
    padding: 0px;
    background: none;
    text-decoration: none;
    color: black;
    width: fit-content;
}

.additional_margin_button {
    margin-top: 40px;
}

.left_margined_button {
    margin-left: 20px;
}

.button_animator {
    border: 2px solid #830019;
    box-shadow: 0 0 12px 3px rgb(0 0 0 / 6%);
    transition: 0.3s ease;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.different_color > .button_animator {
    border: 2px solid #134835;
}


.button_animator_inner {
    width: 40px;
    height: 40px;
    border: 0px;
    background-color: #830019;
    border-radius: 50%;
    transform: scale(0);
    transition: transform 0.3s cubic-bezier(0.61, 1, 0.88, 1);
}

.different_color > .button_animator > .button_animator_inner {
    background-color: #134835;
}

.button_text {
    margin: 0px;
    font-size: 1rem;
    cursor: pointer;
}

.button:hover > .button_animator {
    box-shadow: 0 0 15px 5px rgb(0 0 0 / 15%);
    border-radius: 50px;
    margin-right: 15px;
}

.button:hover > .button_animator > .button_animator_inner {
    transform: scale(1);
}

.custom_dots {
    background-color: #666;
    width: 6px;
    height: 6px;
    border-radius: 50%;
}

.slick-active > .custom_dots {
    background-color: #fff;
}

.MuiTextField-root {
    width: 100% !important;
}

@media only screen and (max-width: 1100px) {
    .section {
        margin: 60px;
    }
}

@media only screen and (max-width: 950px) {
    .section {
        margin: 40px 50px;
    }
}

@media only screen and (max-width: 800px) {
    .button_animator, .button_animator_inner {
        width: 34px;
        height: 34px;
    }
}

@media only screen and (max-width: 600px) {
    .section {
        margin: 25px 30px;
    }
}

@media only screen and (max-width: 570px) {
    .left_margined_button {
        margin-left: 0px;
        margin-top: 0px;
    }
}





