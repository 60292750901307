/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.container {
    padding: 100px 80px;
    background-color: #134835;  
    position: relative;
}

.quote {
    font-size: 2.2rem;
    font-weight: 700;
    color: white;
    margin-bottom: 20px;
    margin-top: 0px;
}

.quoter {
    margin: 0px;
    color: white;
}

.absolute_md {
    font-size: 400px;
    position: absolute;
    left: -20px;
    top: -40px;
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
    color: #103A2A;
}

@media only screen and (max-width: 1100px) {
    .container {
        padding: 90px 60px;
    }
}

@media only screen and (max-width: 950px) {
    .container {
        padding: 70px 50px;
    }
    .quote {
        font-size: 2.0rem;
    }
    .absolute_md {
        font-size: 300px;
    }
}

@media only screen and (max-width: 600px) {
    .container {
        padding: 60px 30px;
    }
    .quote {
        font-size: 1.8rem;
    }
    .absolute_md {
        font-size: 200px;
        top: 50px;
    }
}