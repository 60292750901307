/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.title {
    font-size: 2rem;
    text-align: center;
}

.cursor {
    cursor: pointer;
}

.partner_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 50px 40px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}

.partner_logo_container {
    width: 30%;
    margin-right: 3%;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}

.partner_logo_container > img {
    height: 80px;
    max-width: 100%;
    -o-object-fit: contain;
       object-fit: contain;
    -o-object-position: bottom;
       object-position: bottom;
}

.partner_logo_container > p > b {
    font-size: 0.9rem;
}

@media only screen and (max-width: 950px) {
    .partner_container {
        margin: 40px 30px;
    }
    .partner_logo_container {
        width: 45%;
        margin-right: 5%;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    }
}

@media only screen and (max-width: 600px) {
    .partner_container {
        margin: 35px 20px;
    }
    .partner_logo_container {
        width: 90%;
        margin-right: 5%;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    }
}