/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    z-index: 10;
}

.white_background {
    background-color: rgba(255,255,255,0.4);
    z-index: 1;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.modal {
    position: relative;
    z-index: 2;
    background-color: #eee;
    -webkit-box-shadow: 0 0 15px 5px rgb(0 0 0 / 3%);
            box-shadow: 0 0 15px 5px rgb(0 0 0 / 3%);
    padding: 50px 40px;
    padding-bottom: 30px;
    width: 45%;
    overflow: hidden;
}

.absolute_logo {
    position: absolute;
    width: 300px;
    height: 300px;
    bottom: -80px;
    right: -80px;
    opacity: 0.2;
}

.form_container {
    position: relative;
    z-index: 4;
    margin-bottom: 10px;
}

.close_icon {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 30px;
    cursor: pointer;
    color: #830019;
    -webkit-transition: 0.35s ease;
    -o-transition: 0.35s ease;
    transition: 0.35s ease;
}

.close_icon:hover {
    -webkit-transform: scale(1.08);
        -ms-transform: scale(1.08);
            transform: scale(1.08);
}

.modal > h1 {
    color: #134835;
    margin: 0px;
}

.report_details > p {
    color: #134835;
    font-size: 0.85rem;
    margin: 5px 0px;
    font-weight: 600;
}

.message {
    height: 30px;
    position: relative;
    z-index: 10;
}

.status {
    font-size: 0.8rem;
}

@media only screen and (max-width: 850px) {
    .modal {
        width: 60%;
    }
}

@media only screen and (max-width: 650px) {
    .modal {
        width: 80%;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
    }
}

@media only screen and (max-width: 450px) {
    .modal {
        width: 100%;
        height: 100vh;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
    }
}