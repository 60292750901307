/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    min-height: 475px;
    max-height: 550px;
}

.banner_image {
    width: 42%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
}

.text_container {
    width: 58%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.default > .text_container {
    margin: 0px 80px 0px 40px;
}

.reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
}

.gray {
    background: #f0f0f0;
}

.reverse > .text_container {
    margin: 0px 40px 0px 80px;
}

.title {
    font-size: 2rem;
    margin: 15px 0px;
}

.description > p {
    margin-top: 0px;
    font-size: 1rem;
}

@media only screen and (max-width: 1100px) {
    .banner_image {
        width: 48%;
    }
    
    .text_container {
        width: 52%;
    }
    .default > .text_container {
        margin: 0px 60px 0px 30px;
    }
    .reverse > .text_container {
        margin: 0px 30px 0px 60px;
    }
    .title {
        font-size: 1.8rem;
        margin: 15px 0px;
    }
    
    .description > p {
        margin-top: 0px;
        font-size: 0.9rem;
    }
}

@media only screen and (max-width: 950px) {
    .container {
        min-height: 500px;
        max-height: 600px;
    }
    .title {
        font-size: 1.7rem;
        margin: 15px 0px;
    }
    
    .description > p {
        margin-top: 0px;
        font-size: 0.875rem;
    }
    .default > .text_container {
        margin: 0px 50px 0px 30px;
    }
    .reverse > .text_container {
        margin: 0px 30px 0px 50px;
    }
}

@media only screen and (max-width: 800px) {
    .container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
    .container {
        min-height: unset;
        max-height: unset;
    }
    .banner_image {
        width: 100%;
    }
    
    .text_container {
        width: calc(100% - 100px);
    }
    .default > .text_container {
        margin: 0px 50px;
        margin-top: 35px;
    }
    .reverse > .text_container {
        margin: 0px 50px;
        margin-top: 35px;
    }
}

@media only screen and (max-width: 600px) {
    .text_container {
        width: calc(100% - 60px);
    }
    .default > .text_container {
        margin: 0px 30px;
        margin-top: 30px;
    }
    .reverse > .text_container {
        margin: 0px 30px;
        margin-top: 25px;
        margin-bottom: 25px;
    }
}